$(function () {
//If body height is lesses than window height(means if white space is left at bottom)
    var bodyHeight = $('body').height(),
            windowHeight = $(window).height();
    if (bodyHeight < windowHeight) {
        var heightDiff = parseInt(windowHeight) - parseInt(bodyHeight),
                loginFormHeight = parseInt($('.login-form-container').height()) + heightDiff;
        $('.login-form-container').height(loginFormHeight);
    }
    //END
});